import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class ReportingService extends BaseService {
  static get entity() {
    return 'PlatformLog/SendLog'
  }

  static async sendReport(dataReq) {
    try {
      const formData = new FormData()
      formData.append('request', JSON.stringify(dataReq))
      const response = await this.request({ auth: false }).post('PlatformLog/SendLog', formData)
      const data = {
        content: response.data
      }
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
