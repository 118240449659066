// single pages
import homePage from '../pages/Home.vue'
import Mazars from '../pages/Mazars.vue'
import notFoundPage from '../pages/NotFound.vue'

import { DOMAIN_TITLE } from '../.env'

export const routes = [
  {
    path: '/',
    name: 'index',
    component: homePage,
    meta: { title: `${DOMAIN_TITLE} | Home`, hasMobileView: false }
  },
  {
    path: '/mazars',
    name: 'mazars',
    component: Mazars,
    meta: { title: `${DOMAIN_TITLE}`, hasMobileView: false }
  },
  {
    path: '*',
    component: notFoundPage,
    meta: { title: `${DOMAIN_TITLE} | not found`, hasMobileView: false }
  }
]
