// import { assert } from '@/core'
import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class QuizzService extends BaseService {
  static get entity() {
    return 'quiz/GetMobileQuiz'
  }

  static async getQuizz() {
    try {
      const response = await this.request({ auth: false }).get(`${this.entity}`)
      const data = {
        content: response.data
      }
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getEnquette() {
    try {
      const response = await this.request({ auth: false }).get('quiz/GetEnqueteQuestions')
      const dataEnquette = {
        content: response.data
      }
      return new ResponseWrapper(response, dataEnquette)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async sendMyAnswers(dataReq) {
    try {
      var formData = new FormData()
      formData.append('request', JSON.stringify(dataReq))
      const response = await this.request({ auth: false }).post('quiz/SendAnswers', formData)
      const data = {
        content: response.data
      }
      // console.log(response)
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async sendAnswer(dataReq) {
    try {
      var formData = new FormData()
      formData.append('request', JSON.stringify(dataReq))
      const response = await this.request({ auth: false }).post('quiz/SendQuestionAnswer', formData)
      const data = {
        content: response.data
      }
      // console.log(response)
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async sendEnquetteAnswer(dataReq) {
    try {
      var formData = new FormData()
      formData.append('request', JSON.stringify(dataReq))
      const response = await this.request({ auth: false }).post('quiz/SendEnqueteAnswer', formData)
      const data = {
        content: response.data
      }
      // console.log(response)
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async sendUserForm(dataReq) {
    try {
      var formData = new FormData()
      formData.append('request', JSON.stringify(dataReq))
      const response = await this.request({ auth: false }).post('quiz/SendUser', formData)
      const data = {
        content: response.data
      }
      console.log(response)
      console.log(response.data)
      localStorage.setItem('userServerId', response.data)
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
