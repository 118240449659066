<template>
  <div id="ajax-loading-screen">
    <div class="loading-icon none">
      <img class="lg" src="@/assets/logo_mazars.png" alt="" />
      <div class="material-icon">
        <div class="container">
          <div class="load">LOADING</div>
          <div class="loading-bar">
            <div class="percentage" :style="{ width: percentage + '%' }"></div>
          </div>
          <div class="pr">{{ percent }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      percentage: 0
    }
  },
  computed: {
    percent() {
      return this.percentage.toFixed()
    }
  },
  created() {
    var intval = setInterval(() => {
      if (this.percentage < 100) {
        this.percentage += 0.45
      } else {
        clearInterval(intval)
      }
    }, 10)
  }
}
</script>
<style lang="scss" scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#ajax-loading-screen {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 1029;
}
#ajax-loading-screen .loading-icon {
  background-repeat: no-repeat;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.default-loading-icon {
  top: 50%;
  height: 30px;
  width: 400px;
  margin: 0 auto;
  display: block;
  position: relative;
  border: 3px solid #e5e5e5;
  margin-top: -20px;
}
.loading-icon .material-icon {
  top: 50%;
  left: 50%;
  height: 65px;
  width: 65px;
  position: fixed;
  display: block;
}
.container {
  text-align: center;
  font-size: 2rem;
  color: rgb(0, 0, 0);
  font-weight: 650;
  font-family: 'Roboto';
  margin-top: 20px;
  .load {
    margin-bottom: 20px;
    font-size: 15px;
  }
}

.loading-bar {
  position: relative;
  right: 150px;
  width: 350px;
  height: 20px;
  border-radius: 15px;
  overflow: hidden;
  border-bottom: 2px solid transparent;
  .percentage {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    display: block;
    height: 100%;
    border-radius: 15px;
    background-color: #fff;
    background-size: 30px 30px;
    background: linear-gradient(
      90deg,
      rgba(0, 2, 99, 1) 0%,
      rgba(9, 47, 255, 1) 23%,
      rgba(45, 67, 255, 1) 37%,
      rgba(55, 76, 251, 1) 50%,
      rgba(0, 113, 206, 1) 70%,
      rgba(0, 113, 206, 1) 89%
    );
    /*  animation: animate-stripes 3s linear; */
  }
}
.lg {
  background-color: #fff;
  top: 5%;
  width: auto;
  height: auto;
  position: fixed;
  border-radius: 0% 10% 10% 0%;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 50px;
    height: auto;
  }
  .loading-bar {
    width: 250px;
    height: 20px;
  }
  .load {
    margin-left: -50px;
  }
  .pr {
    margin-left: -50px;
  }
}
</style>
