/**
 * import and init third party
 * components/validators and other dependencies
 */

import Vue from 'vue'
import * as THREE from 'three'
import { TweenLite } from 'gsap'
import { ReportingService } from '@/services/reporting.service'
import {
  CHROMA_VIDEOS, Camera_Positions, TOOLTIP, Scene_Names
} from '@/.env'
THREE.OrbitControls = require('three-orbit-controls')(THREE)

export class Scene {
  camera
  scenes = []
  layer
  renderer
  controls = null
  isUserInteracting = false
  tooltipActive = false
  lon = 0
  lat = 0
  phi = 0
  theta = 0
  onPointerDownPointerX = 0
  onPointerDownPointerY = 0
  onPointerDownLon = 0
  onPointerDownLat = 0
  points = []
  emitSpriteHoverOut = true
  emitSpriteHoverIn = false
  langue
  position
  questionIndex

  constructor(ele, rdcWelcome, rdcHall, rdcOpenSpace, rdcOpenSpaceHall, rdcOutSide, rdcMeetingRoom
  ) {
    this.container = ele
    this.rdcWelcome = rdcWelcome
    this.rdcHall = rdcHall
    this.rdcOpenSpace = rdcOpenSpace
    this.rdcOpenSpaceHall = rdcOpenSpaceHall
    this.rdcOutSide = rdcOutSide
    this.rdcMeetingRoom = rdcMeetingRoom
    this.raycaster = new THREE.Raycaster()
    this.renderer = new THREE.CSS3DRenderer({ antialias: true, alpha: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  // Creating Sphere
  createSphere(
    textureMaterial = 'https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/2294472375_24a3b8ef46_o.jpg'
  ) {
    const name = 'environmentSphere'
    const geometry = new THREE.SphereGeometry(100, 55, 55)
    const texture = new THREE.TextureLoader().load(textureMaterial)
    texture.depthWrite = false
    texture.wrapS = THREE.RepeatWrapping
    texture.repeat.x = -1
    texture.mapping = THREE.EquirectangularReflectionMapping
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide
    })
    material.transparent = true
    const mesh = new THREE.Mesh(geometry, material)
    mesh.name = name
    return mesh
  }

  sendStats(typeS, detailS) {
    const data = {
      type: typeS,
      detail: detailS,
      version: localStorage.getItem('version'),
      SessionId: localStorage.getItem('userID')
    }
    ReportingService.sendReport(data)
    // console.log(res)
  }

  // making video play
  playVideo(
  ) {
    const video = document.getElementById('video')
    video.play()
  }

  // Creating sprite
  addSprite(
    point = new THREE.Vector3(14, 1.9, -47),
    name = 'nextScene',
    popoverContent = 'content',
    texture = '/Mazars/info.png'
  ) {
    const map = new THREE.TextureLoader().load(texture)
    const spriteMaterial = new THREE.SpriteMaterial({ map: map, alphaTest: 0.5 })
    const sprite = new THREE.Sprite(spriteMaterial)
    sprite.name = name
    sprite.scale.multiplyScalar(2.5)
    sprite.popoverContent = popoverContent
    // On place le point un peu plus proche de la caméra pour un léger effet de parallaxe
    sprite.position.copy(
      point
        .clone()
        .normalize()
        .multiplyScalar(20)
    )
    return sprite
  }

  // Function to move to another scene
  goToScene(sceneIndex) {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const nextScene = this.scenes[sceneIndex] || this.scenes[0]
    const position = Camera_Positions[sceneIndex]
    var Scene_Name = Scene_Names[sceneIndex].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)

    const video = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[sceneIndex] !== 'undefined') {
      video.setAttribute('src', CHROMA_VIDEOS[sceneIndex][this.langue])
    }
    video.pause()
    video.currentTime = 0
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites(sceneIndex)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  changeToScene1() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene1 = new THREE.Scene()
    scene1.name = 'rdc Hall'
    scene1.add(this.createSphere(this.rdcHall))
    scene1.add(this.addSprite(new THREE.Vector3(-0.7, -1.2, 0.7), 'changeToScene0', 'rdc Welcome', ''))
    scene1.add(this.addSprite(new THREE.Vector3(12, 0.4, 1.5), 'clickedToPlay2', 'info', '/Mazars/marker.png'))
    scene1.add(this.addSprite(new THREE.Vector3(1, -0.5, 0.5), 'changeToScene2', 'rdc OpenSpace', ''))
    
    const nextScene = scene1
    const prevScene = this.currentScene
    const position = Camera_Positions[1]
    var Scene_Name = Scene_Names[1].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)

    const video = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[1] !== 'undefined') {
      video.setAttribute('src', CHROMA_VIDEOS[1][this.langue])
    }
    video.pause()
    video.currentTime = 0
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene0() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene0 = new THREE.Scene()
    scene0.name = 'rdcWelcome'
    scene0.add(this.createSphere(this.rdcWelcome))
    scene0.add(this.addSprite(new THREE.Vector3(0.32, -0.37, -0.7), 'changeToScene1', 'rdc Hall', ''))
    scene0.add(this.addSprite(new THREE.Vector3(15, 3, 0), 'clickedToPlay1', 'info', '/Mazars/marker.png'))
    scene0.add(this.addSprite(new THREE.Vector3(15, 1.5, 0), 'clickedToQuizz0', 'quizz', '/Mazars/marker.png'))
    
    const nextScene = scene0
    const prevScene = this.currentScene
    const position = Camera_Positions[0]
    var Scene_Name = Scene_Names[0].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)

    const video = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[0] !== 'undefined') {
      video.setAttribute('src', CHROMA_VIDEOS[0][this.langue])
    }
    video.pause()
    video.currentTime = 0
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene2() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene2 = new THREE.Scene()

    scene2.name = 'rdc openSpace'
    scene2.add(this.createSphere(this.rdcOpenSpace))
    scene2.add(this.addSprite(new THREE.Vector3(0.4, 0.01, 0.3), 'changeToScene1', 'rdc Hall', ''))
    scene2.add(this.addSprite(new THREE.Vector3(-12, 0.5, -16), 'clickedToPlay3', 'info', 'Département Outsourcing'))
    scene2.add(this.addSprite(new THREE.Vector3(-10, 0.5, -16), 'clickedToQuizz3', 'quizz', '/Mazars/marker.png'))
    scene2.add(this.addSprite(new THREE.Vector3(-2.8, -0.14, -6), 'changeToScene3', 'rdc openSpaceHall', ''))
    
    const nextScene = scene2
    const prevScene = this.currentScene
    const position = Camera_Positions[2]
    var Scene_Name = Scene_Names[2].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)

    const video = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[2] !== 'undefined') {
      video.setAttribute('src', CHROMA_VIDEOS[2][this.langue])
    }
    video.pause()
    video.currentTime = 0
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene3() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene3 = new THREE.Scene()
    scene3.name = 'rdc openSpaceHall'
    scene3.add(this.createSphere(this.rdcOpenSpaceHall))
    scene3.add(this.addSprite(new THREE.Vector3(0.15, -0.55, 0.6), 'changeToScene2', 'rdc OpenSpace', ''))
    scene3.add(this.addSprite(new THREE.Vector3(0.22, -0.05, -0.3), 'changeToScene4', 'rdc Outside', ''))
    scene3.add(this.addSprite(new THREE.Vector3(-0.8, 0.7, -20), 'clickedToPlay4', 'info', '/Mazars/marker.png'))
    scene3.add(this.addSprite(new THREE.Vector3(-0.3, -0.5, -1), 'changeToScene5', 'rdc MeetingRoom', ''))
    
    const nextScene = scene3
    const prevScene = this.currentScene
    const position = Camera_Positions[3]
    var Scene_Name = Scene_Names[3].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)

    const video = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[3] !== 'undefined') {
      video.setAttribute('src', CHROMA_VIDEOS[3][this.langue])
    }
    video.pause()
    video.currentTime = 0
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene4() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene4 = new THREE.Scene()
    scene4.name = 'rdc OutSide'
    scene4.add(this.createSphere(this.rdcOutSide))
    scene4.add(this.addSprite(new THREE.Vector3(0.65, 0, -1), 'changeToScene3', 'rdc OpenSpaceHall', ''))
    scene4.add(this.addSprite(new THREE.Vector3(-18, 1.5, 6.5), 'clickedToPlay5', 'info', '/Mazars/marker.png'))
    scene4.add(this.addSprite(new THREE.Vector3(-18, 1.5, 5), 'clickedToQuizz4', 'quizz', '/Mazars/marker.png'))
    
    const nextScene = scene4
    const prevScene = this.currentScene
    const position = Camera_Positions[4]
    var Scene_Name = Scene_Names[4].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)

    const video = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[4] !== 'undefined') {
      video.setAttribute('src', CHROMA_VIDEOS[4][this.langue])
    }
    video.pause()
    video.currentTime = 0
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene5() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene5 = new THREE.Scene(this.camera.position.set(14, 10, -50))
    scene5.name = 'rdc meetingRoom'
    scene5.add(this.createSphere(this.rdcMeetingRoom))
    scene5.add(this.addSprite(new THREE.Vector3(0, 0, -1), 'changeToScene3', 'rdc OpenSpaceHall', ''))
    scene5.add(this.addSprite(new THREE.Vector3(16, 5.15, -10.5), 'clickedToPlay6', 'info', '/Mazars/marker.png'))
    scene5.add(this.addSprite(new THREE.Vector3(16, 4.75, -6), 'clickedToQuizz5', 'quizz', '/Mazars/marker.png'))
    scene5.add(this.addSprite(new THREE.Vector3(16, 4.85, -7.5), '', 'play', '/Mazars/marker.png'))
    scene5.add(this.addSprite(new THREE.Vector3(16, 5, -9), '', '', '/Mazars/marker.png'))
    if (this.langue === 'fr') {
      scene5.add(this.playme(1.07, 1.92, 5, 0, -67, 0, 1, new THREE.Vector3(65, -15, -20)))
    } else {
      scene5.add(this.playme(1.07, 1.92, 5, 0, -70, 0, 1, new THREE.Vector3(72, -17, -20)))
    }
    const video = document.getElementById('video')
    video.setAttribute('src', CHROMA_VIDEOS[5][this.langue])
    
    const nextScene = scene5
    const prevScene = this.currentScene
    const position = Camera_Positions[5]
    var Scene_Name = Scene_Names[5].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)

    const video2 = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[5] !== 'undefined') {
      video2.setAttribute('src', CHROMA_VIDEOS[5][this.langue])
    }
    video2.pause()
    video2.currentTime = 0
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  // Function to move to the Next scene
  goToNextScene() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const currentSceneIndex = this.scenes.findIndex(s => s.uuid === this.currentScene.uuid)
    const nextScene = this.scenes[currentSceneIndex + 1] || this.scenes[0]

    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites()
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(-0.1, 0, 0)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  // Function to move to the previous scene
  goToPrevScene() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const currentSceneIndex = this.scenes.findIndex(s => s.uuid === this.currentScene.uuid)
    const nextScene = this.scenes[currentSceneIndex - 1] || this.scenes[0]

    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites()
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => { }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(-0.1, 0, 0)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  // remove sprite after moving to another scene
  removeSprites = (sceneIndex) => {
    this.EventBus.$emit('i-got-hover-out')
    const sprites = this.currentScene.children.filter(o => o.type === 'Sprite')
    const nextScene = this.scenes[sceneIndex] || this.scenes[0]
    const nextSceneSprites = nextScene.children.filter(o => o.type === 'Sprite')
    sprites.map(s => {
      TweenLite.to(s.scale, 1, {
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          nextSceneSprites.map(ns => {
            TweenLite.to(ns.scale, 1, {
              x: 4,
              y: 4,
              z: 4,
              onComplete: () => { }
            })
          })
        }
      })
    })
  }

  removeSceneSprites = (newScene) => {
    this.EventBus.$emit('i-got-hover-out')
    const sprites = this.currentScene.children.filter(o => o.type === 'Sprite')
    const nextScene = newScene
    const nextSceneSprites = nextScene.children.filter(o => o.type === 'Sprite')
    sprites.map(s => {
      TweenLite.to(s.scale, 1, {
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          nextSceneSprites.map(ns => {
            TweenLite.to(ns.scale, 1, {
              x: 4,
              y: 4,
              z: 4,
              onComplete: () => { }
            })
          })
        }
      })
    })
  }

  clickedToQuizz(Q) {
    const questionIndex = Q
    console.log('QI', questionIndex)
    localStorage.setItem('questionIndex', questionIndex)
    return questionIndex
  }

  // making videoTexture into plane geometry to play on Threejs
  playme(
    x = 0,
    y = 0,
    z = 0,
    degreeX = 0,
    degreeY = 0,
    degreeZ = 0,
    videoIndex = 0,
    position = new THREE.Vector3(-14, 0, 147)
  ) {
    const video = document.getElementById('video')
    // video.setAttribute('src', CHROMA_VIDEOS[videoIndex][this.langue])
    // Create your video texture:
    const videoTexture = new THREE.VideoTexture(video)
    videoTexture.format = THREE.RGBAFormat
    const videoMaterial = new THREE.MeshBasicMaterial({
      color: 'white',
      map: videoTexture,
      side: THREE.FrontSide,
      transparent: true
    })

    // Create screen using planeGeometry
    const screen = new THREE.BoxGeometry(x, y)
    const videoScreen = new THREE.Mesh(screen, videoMaterial)
    videoScreen.renderOrder = 2

    // Make dynamic rotation
    videoScreen.rotateX(THREE.Math.degToRad(degreeX))
    videoScreen.rotateY(THREE.Math.degToRad(degreeY))
    videoScreen.rotateZ(THREE.Math.degToRad(degreeZ))
    videoScreen.position.copy(position)
    videoScreen.scale.multiplyScalar(55)

    return videoScreen
  }

  stop(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 0,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'stop',
    popoverContent = 'stop'
  ) {
    const video = document.getElementById('stop')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    this.camera.position.set(200, 50, 400)
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.PlaneGeometry(-1.5, 1.5, 1)
    screen.name = name
    screen.popoverContent = name
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  }

  play(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 0,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'playVideo',
    popoverContent = 'play'
  ) {
    const video = document.getElementById('play')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    this.camera.position.set(200, 50, 400)
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.PlaneGeometry(-1.5, 1.5, 1)
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  }

  quizz(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 2,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'clickedToQuizz',
    popoverContent = 'Quizz'
  ) {
    const video = document.getElementById('quizz')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.PlaneGeometry(-1.5, 1.5, 1)
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  }

  info(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 1,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'info',
    popoverContent = 'info'
  ) {
    const video = document.getElementById('info')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.BoxGeometry(-1.5, 1.5, 1)
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  }

  init() {
    // initiate camera position
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1100)
    this.camera.target = new THREE.Vector3(0, 0, 0)
    this.camera.position.set(0, 0, 0)

    this.langue = localStorage.getItem('langue')

    // Creating scene 0 and adding button to interact with
    const scene0 = new THREE.Scene()
    scene0.name = 'rdcWelcome'
    scene0.add(this.createSphere(this.rdcWelcome))
    scene0.add(this.addSprite(new THREE.Vector3(0.32, -0.37, -0.7), 'changeToScene1', 'rdc Hall', ''))
    scene0.add(this.info(0, 90, 0, 1, new THREE.Vector3(15, 3, 0), 'clickedToPlay1', 'info'))
    scene0.add(this.addSprite(new THREE.Vector3(15, 1.5, 0), 'clickedToQuizz0', 'quizz', '/Mazars/marker.png'))
    /* this.scenes.push(scene0)

    // Creating scene 1 and adding button to interact with
    const scene1 = new THREE.Scene()
    scene1.name = 'rdc Hall'
    scene1.add(this.createSphere(this.rdcHall))
    scene1.add(this.addSprite(new THREE.Vector3(-0.7, -1.2, 0.7), 'goToScene0', 'rdc Welcome', ''))
    scene1.add(this.addSprite(new THREE.Vector3(12, 0.4, 1.5), 'clickedToPlay2', 'info', '/Mazars/marker.png'))
    scene1.add(this.addSprite(new THREE.Vector3(1, -0.5, 0.5), 'goToScene2', 'rdc OpenSpace', ''))
    this.scenes.push(scene1) */

    // Creating scene 2 and adding button to interact with
    /* const scene2 = new THREE.Scene()
    scene2.name = 'rdc openSpace'
    scene2.add(this.createSphere(this.rdcOpenSpace))
    scene2.add(this.addSprite(new THREE.Vector3(0.4, 0.01, 0.3), 'goToScene1', 'rdc Hall', ''))
    scene2.add(this.addSprite(new THREE.Vector3(-12, 0.5, -16), 'clickedToPlay3', 'info', 'Département Outsourcing'))
    scene2.add(this.addSprite(new THREE.Vector3(-10, 0.5, -16), 'clickedToQuizz3', 'quizz', '/Mazars/marker.png'))
    scene2.add(this.addSprite(new THREE.Vector3(-2.8, -0.14, -6), 'goToScene3', 'rdc openSpaceHall', ''))
    this.scenes.push(scene2)

    // Creating scene 3 and adding button to interact with
    const scene3 = new THREE.Scene()
    scene3.name = 'rdc openSpaceHall'
    scene3.add(this.createSphere(this.rdcOpenSpaceHall))
    scene3.add(this.addSprite(new THREE.Vector3(0.15, -0.55, 0.6), 'goToScene2', 'rdc OpenSpace', ''))
    scene3.add(this.addSprite(new THREE.Vector3(0.22, -0.05, -0.3), 'goToScene4', 'rdc Outside', ''))
    scene3.add(this.addSprite(new THREE.Vector3(-0.8, 0.7, -20), 'clickedToPlay4', 'info', '/Mazars/marker.png'))
    scene3.add(this.addSprite(new THREE.Vector3(-0.3, -0.5, -1), 'goToScene5', 'rdc MeetingRoom', ''))
    this.scenes.push(scene3)

    // Creating scene 4 and adding button to interact with
    const scene4 = new THREE.Scene()
    scene4.name = 'rdc OutSide'
    scene4.add(this.createSphere(this.rdcOutSide))
    scene4.add(this.addSprite(new THREE.Vector3(0.65, 0, -1), 'goToScene3', 'rdc OpenSpaceHall', ''))
    scene4.add(this.addSprite(new THREE.Vector3(-18, 1.5, 6.5), 'clickedToPlay5', 'info', '/Mazars/marker.png'))
    scene4.add(this.addSprite(new THREE.Vector3(-18, 1.5, 5), 'clickedToQuizz4', 'quizz', '/Mazars/marker.png'))
    this.scenes.push(scene4)

    // Creating scene 5 and adding button to interact with
    const scene5 = new THREE.Scene(this.camera.position.set(14, 10, -50))
    scene5.name = 'rdc meetingRoom'
    scene5.add(this.createSphere(this.rdcMeetingRoom))
    scene5.add(this.addSprite(new THREE.Vector3(0, 0, -1), 'goToScene3', 'rdc OpenSpaceHall', ''))
    scene5.add(this.addSprite(new THREE.Vector3(16, 5.15, -10.5), 'clickedToPlay6', 'info', '/Mazars/marker.png'))
    scene5.add(this.addSprite(new THREE.Vector3(16, 4.75, -6), 'clickedToQuizz5', 'quizz', '/Mazars/marker.png'))
    scene5.add(this.addSprite(new THREE.Vector3(16, 4.85, -7.5), '', 'play', '/Mazars/marker.png'))
    scene5.add(this.addSprite(new THREE.Vector3(16, 5, -9), '', '', '/Mazars/marker.png'))
    if (this.langue === 'fr') {
      scene5.add(this.playme(1.07, 1.92, 5, 0, -67, 0, 1, new THREE.Vector3(65, -15, -20)))
    } else {
      scene5.add(this.playme(1.07, 1.92, 5, 0, -70, 0, 1, new THREE.Vector3(72, -17, -20)))
    }
    const video = document.getElementById('video')
    video.setAttribute('src', CHROMA_VIDEOS[5][this.langue])
    this.scenes.push(scene5) */

    // initialize the camera position into scene 0
    this.currentScene = scene0
    this.container.appendChild(this.renderer.domElement)
    // init controle to move around
    this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement)
    this.controls.rotateSpeed = -0.5
    this.controls.enableZoom = true
    this.controls.enablePan = false
    this.controls.maxDistance = 2
    this.camera.position.set(-80, 1, -40)
    this.controls.update()
    this.container.addEventListener('mousemove', this.onMouseMove, false)
    this.container.addEventListener('click', event => {
      const x = (event.clientX / window.innerWidth) * 2 - 1
      const y = -(event.clientY / window.innerHeight) * 2 + 1
      this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera)
      const intersects = this.raycaster.intersectObjects(this.currentScene.children)
      intersects.forEach(element => {
        console.log(element.object.name)
      })

      const intersectsObjects = intersects.filter(i => i.object.type === 'Sprite' || i.object.type === 'area' || i.object.type === 'Mesh')
      if (intersectsObjects) {
        intersectsObjects.map(io => {
          console.log('clicked' + io.object.name)
          if (io.object.name === 'nextScene') {
            this.goToNextScene()
          } else if (io.object.name === 'prevScene') {
            this.goToPrevScene()
          } else if (io.object.name === 'changeToScene1') {
            this.changeToScene1()
          } else if (io.object.name === 'changeToScene0') {
            this.changeToScene0()
          } else if (io.object.name === 'changeToScene2') {
            this.changeToScene2()
          } else if (io.object.name === 'changeToScene3') {
            this.changeToScene3()
          } else if (io.object.name === 'changeToScene4') {
            this.changeToScene4()
          } else if (io.object.name === 'changeToScene5') {
            this.changeToScene5()
          } else if (io.object.name.slice(0, -1) === 'goToScene') {
            this.goToScene(+io.object.name.slice(-1))
          } else if (io.object.name.slice(0, -2) === 'goToScene') {
            this.goToScene(+io.object.name.slice(-2))
          } else if (io.object.name.slice(0, -1) === 'clickedToPlay') {
            this.EventBus.$emit('clickedToPlay', {
              playIndex: +io.object.name.substr(io.object.name.length - 1),
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToPlay') {
            this.EventBus.$emit('clickedToPlay', {
              playIndex: +io.object.name.substr(io.object.name.length - 2),
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          } else if (io.object.name.slice(0, -1) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-1))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-2))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-2))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          } else if (io.object.name === 'clickedToGoPage') {
            this.EventBus.$emit('clickedToGoPage', {
              url: '/voting',
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          } else if (io.object.name === 'playVideo') {
            this.EventBus.$emit('playVideo', {
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          } else if (io.object.name === 'stop') {
            this.EventBus.$emit('stop', {
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          }
        })
      }
    })

    this.animate()
  }

  animate = () => {
    requestAnimationFrame(this.animate)
    this.update()
  }

  update = () => {
    this.renderer.render(this.currentScene, this.camera)
  }

  // making the sphere Responsive
  onResize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
  }

  onMouseMove = event => {
    /* const mouse = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    ) */
    const x = (event.clientX / window.innerWidth) * 2 - 1
    const y = -(event.clientY / window.innerHeight) * 2 + 1
    this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera)
    const intersects = this.raycaster.intersectObjects(this.currentScene.children)
    const intersectsSprite = intersects.find(i => i.object.type === 'Sprite' || i.object.type === 'Mesh')

    if (intersectsSprite && !this.emitSpriteHoverIn && intersectsSprite.object.name !== '' && intersectsSprite.object.name !== 'environmentSphere') {
      const p = intersectsSprite.object.position.clone().project(this.camera)
      this.EventBus.$emit('i-got-hover-in', {
        sprite: intersectsSprite,
        mouseCoordinates: { y: ((-1 * p.y + 1) * window.innerHeight) / 2, x: ((p.x + 1) * window.innerWidth) / 2 },
        popoverContent: intersectsSprite.object.popoverContent
      })
      this.emitSpriteHoverIn = true
      this.emitSpriteHoverOut = false
    } else if ((!intersectsSprite && !this.emitSpriteHoverOut) || (intersectsSprite && (intersectsSprite.object.name === '' || intersectsSprite.object.name === 'environmentSphere'))) {
      this.EventBus.$emit('i-got-hover-out')
      this.emitSpriteHoverOut = true
      this.emitSpriteHoverIn = false
    }
  }

  addPoint(sceneIndex = 0) {
    const map = new THREE.TextureLoader().load('https://images-na.ssl-images-amazon.com/images/I/41Y4fyn7HAL.png')
    const arrowMaterial = new THREE.SpriteMaterial({ map: map })
    arrowMaterial.transparent = true
    const point = new THREE.Vector3(14, 1.9, -47)
    const sprite = new THREE.Sprite(arrowMaterial)
    sprite.name = 'enter'
    // On place le point un peu plus proche de la caméra pour un léger effet de parallaxe
    sprite.position.copy(
      point
        .clone()
        .normalize()
        .multiplyScalar(20)
    )
    this.scenes[sceneIndex].add(sprite)
    this.points.push(point)
  }

  destroy() {
    this.camera = null
    this.currentScene = null
    this.scenes = []
    this.renderer = null
    this.points = []
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
  }

  EventBus = new Vue()
}
