var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"swiper-slide",attrs:{"data-hash":"slide1","data-title":_vm.slide.title,"data-thumb-url":_vm.slide.thumbUrl}},[(!_vm.isLoaded)?_c('UiPulseLoading'):_vm._e(),_c('header',[(!_vm.justImage)?_c('div',{staticClass:"pattern pattern-1"}):_vm._e(),(!_vm.justImage)?_c('div',{staticClass:"pattern back-35-g"}):_vm._e(),_c('div',{staticClass:"header-image",style:([
        _vm.isActive
          ? {
              backgroundImage: 'url(' + _vm.slide.backgroundImage + ')',
              backgroundPosition: ("50% " + (_vm.scrollData.backgroundPosition) + "px")
            }
          : {},
        _vm.justImage
          ? {
              backgroundSize: 'contain!important'
            }
          : {},
        { backgroundImage: 'url(' + _vm.slide.backgroundImage + ')', backgroundRepeat: 'no-repeat' }
      ])}),_c('div',{staticClass:"container mx-auto mt-6",style:([
        _vm.isActive
          ? {
              position: 'relative',
              opacity: _vm.scrollData.opacity,
              top: ((_vm.scrollData.top) + "px")
            }
          : {}
      ])},[_c('div',{class:_vm.readyToAnimate ? ("animate__animated " + (_vm.slide.animatation)) : 'invisible'},[(!_vm.justImage && _vm.content)?_c('div',{staticClass:"text-white text-center body-font"},[_c('div',{staticClass:"container mx-auto pt-2"},[_c('img',{staticClass:"img-fluid mb-5",attrs:{"src":require(("@/assets/" + (_vm.content.image))),"width":"450px","alt":""}}),_c('h1',{staticClass:"text-shadow-lg display-3 font-weight-bold text-white",staticStyle:{"font-size":"calc(100% + 3vw + 3vh)"},domProps:{"innerHTML":_vm._s(_vm.content.textH1)}})])]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }