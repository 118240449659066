<template>
  <article class="swiper-slide" data-hash="slide1" :data-title="slide.title" :data-thumb-url="slide.thumbUrl">
    <UiPulseLoading v-if="!isLoaded"></UiPulseLoading>
    <header>
      <div v-if="!justImage" class="pattern pattern-1"></div>
      <div v-if="!justImage" class="pattern back-35-g"></div>
      <div
        class="header-image"
        :style="[
          isActive
            ? {
                backgroundImage: 'url(' + slide.backgroundImage + ')',
                backgroundPosition: `50% ${scrollData.backgroundPosition}px`
              }
            : {},
          justImage
            ? {
                backgroundSize: 'contain!important'
              }
            : {},
          { backgroundImage: 'url(' + slide.backgroundImage + ')', backgroundRepeat: 'no-repeat' }
        ]"
      ></div>
      <div
        class="container mx-auto mt-6"
        :style="[
          isActive
            ? {
                position: 'relative',
                opacity: scrollData.opacity,
                top: `${scrollData.top}px`
              }
            : {}
        ]"
      >
        <div :class="readyToAnimate ? `animate__animated ${slide.animatation}` : 'invisible'">
          <div v-if="!justImage && content" class="text-white text-center body-font">
            <div class="container mx-auto pt-2">
              <img :src="require(`@/assets/${content.image}`)" width="450px" class="img-fluid mb-5" alt="" />
              <h1
                class="text-shadow-lg display-3 font-weight-bold text-white"
                style="font-size: calc(100% + 3vw + 3vh);"
                v-html="content.textH1"
              ></h1>
            </div>
          </div>
        </div>
      </div>
    </header>
  </article>
</template>
<script>
import { ref, onMounted, watch } from '@vue/composition-api'

import UiPulseLoading from '../progress-loaders/UiPulseLoading.vue'
export default {
  name: 'slide',
  components: {
    UiPulseLoading
  },
  props: {
    slide: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    scrollData: {
      type: Object,
      required: true
    },
    justImage: {
      type: Boolean,
      required: false,
      default: false
    },
    autoplay: {
      required: true
    },
    content: {
      type: Object,
      required: false,
      default: null
    }
  },
  setup(props, { emit }) {
    const swiperImage = ref(null)
    const isLoaded = ref(false)
    const readyToAnimate = ref(true)
    const imageIsLoaded = src => {
      const image = document.createElement('img')
      image.src = src
      return new Promise((resolve, reject) => {
        image.onload = () => {
          resolve(true)
        }
        image.onerror = error => {
          reject(error)
        }
      })
    }
    watch(
      () => props.isActive,
      (val, oldValue) => {
        if (val && isLoaded.value) {
          setTimeout(() => {
            readyToAnimate.value = true
          }, 100)
        } else if (oldValue) {
          readyToAnimate.value = false
        }
      }
    )
    onMounted(async () => {
      let loaded = false
      await imageIsLoaded(props.slide.backgroundImage)
        .then(result => (loaded = result))
        .catch(error => {
          console.error(error)
        })
      isLoaded.value = loaded
      if (props.slide.index === 0 && loaded) {
        setTimeout(() => {
          readyToAnimate.value = true
        }, 1000)
      }
      emit('imageLoaded', {
        url: props.slide.backgroundImage,
        loaded
      })
    })
    return {
      swiperImage,
      isLoaded,
      readyToAnimate
    }
  }
}
</script>
<style lang="scss"></style>
