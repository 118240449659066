<template>
  <div class="app-wrapper">
    <div><img class="img" src="@/assets/image_accueil.png" alt="" /></div>
    <div><img class="filter" src="@/assets/flitre.png" alt="" /></div>
    <div class="row">
      <div class="col-sm-4">
        <img class="lg" src="@/assets/téléchargement.png" />
      </div>
      <div class=" col-sm-8">
        <p class="c">choisir la langue <br />de sous-titrage</p>
        <div class="navul ">
          <img class="hh img-responsive" src="../assets/bg.png" />
          <a @click="setLanguage('fr')"> <img class="fr" :src="srcf"/></a>
          <a @click="setLanguage('en')"> <img class="en" :src="srcE"/></a>
          <a @click="setLanguage('ar')"> <img class="ar" :src="srcA"/></a>
        </div>
      </div>
    </div>
    <div class="row">
        <img class="mg" src="../assets/text.png">
    </div>
    <div class="row justify-content-md-center">
        <button type="button" class="btn" @click="goTo('mazars')">
          Démarrer l'expérience
        </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

import { ReportingService } from '@/services/reporting.service'

import { v4 as uuid } from 'uuid'
import srcfr from '@/assets/fro.png'
import srcfro from '@/assets/fr.png'
import srcEn from '@/assets/eno.png'
import srcEo from '@/assets/en.png'
import srcAr from '@/assets/aro.png'
import srcAo from '@/assets/ar.png'

export default {
  name: 'IndexPage',
  components: {},
  setup() {
    const count = ref(0)
    const slides = ref([])
    const inc = () => {
      count.value++
    }
    onMounted(async () => {
      const myAnimation = [
        'animate__fadeInLeftBig',
        'animate__fadeIn',
        'animate__fadeInUpBig',
        'animate__fadeInRightBig'
      ]
      slides.value.push({
        id: 'k-Cf0kRruew',
        thumbUrl: '/images/exterieur200.jpg',
        backgroundImage: '/images/exterieur.jpg',
        title: `${1}/3`,
        animatation: myAnimation[2]
      })
      slides.value.push({
        id: 'lUO-BjCiZEA',
        thumbUrl: '/images/eljem200.jpg',
        backgroundImage: '/images/eljem.jpg',
        title: `${2}/3`,
        animatation: myAnimation[3]
      })
      slides.value.push({
        id: 'cjBLfrjE-XU',
        thumbUrl: '/images/interieur200.jpg',
        backgroundImage: '/images/interieur.jpg',
        title: `${3}/3`,
        animatation: myAnimation[1]
      })
    })
    return {
      count,
      inc,
      slides
    }
  },
  data() {
    return {
      srcf: srcfro,
      srcfro: srcfro,
      srcE: srcEn,
      srcEo: srcEo,
      srcA: srcAr,
      srcAo: srcAo,
      langue: 'fr',
      visible: true,
      msg: 'Mazars 360°',
      isShownModal: false,
      inputError: false,
      checkboxState: false,

      pagination: {
        limit: 10,
        offset: 0,
        total: 60
      }
    }
  },
  async mounted() {
    if (localStorage.getItem('userID') === null) {
      localStorage.setItem('userID', uuid())
    }
    const data = {
      type: 'page',
      detail: 'userID',
      SessionId: localStorage.getItem('userID')
    }
    await ReportingService.sendReport(data)
  },
  methods: {
    setLanguage(lg) {
      // this.langue = lg
      if (lg === 'fr') {
        this.srcf = srcfro
        this.srcE = srcEn
        this.srcA = srcAr
      } else if (lg === 'en') {
        this.srcf = srcfr
        this.srcE = srcEo
        this.srcA = srcAr
      } else {
        this.srcf = srcfr
        this.srcE = srcEn
        this.srcA = srcAo
      }
      localStorage.setItem('langue', lg)
    },
    goTo(link) {
      // localStorage.setItem('langue', langue)
      // this.$router.push(link)
      window.location.href = '/mazars'
    }
  },
  toggle: function() {
    this.visible = !this.visible
  }
}
</script>

<style lang="scss" scoped>
.navul {
  color: #fff;
  text-align: center;
  position: fixed;
  top: 5%;
  right: 2%;
  animation: vibrator 1s ease-out;
}
.filter {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.img {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
}
.btn {
  position: fixed;
  font-size: 30px;
  font-weight: 600;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  border-radius: 40px 40px 40px 40px;
  text-align: center;
  margin: 0;
  top: 80%;
  background: transparent;
  border: 3px solid #fff;
  animation: pulse 1s infinite, vibrator 2s ease-out;
}
.btn:hover {
  background-color: #fff;
}
.center {
  display: block;
  margin-top: 20%;
  margin-left: 10%;
  margin-right: 10%;
}
.mg{
  top: 40%;
  left: 10%;
  width: 80%;
  position:fixed;
  animation: vibrator 2s ease-out;
}
.c {
  top: 5%;
  right: 9%;
  position: fixed;
  font-family: 'Roboto';
  color: white;
  font-size: 20px;
  animation: vibrator 1s ease-out;
}
.lg {
  position: absolute;
  background-color: #fff;
  top: 5%;
  width: auto;
  height: 10%;
  position: fixed;
  animation: fade 2s ease-out;
}
.fr {
  position: absolute;
  right: -25%;
  top: 0%;
}
.en {
  position: absolute;
  right: -25%;
  top: 30%;
}
.ar {
  position: absolute;
  right: -25%;
  top: 60%;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.51);
  }
}
@keyframes fade {
  from {
    opacity: 0;
    transform: translate(-100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}
@keyframes vibrator {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  /* .b {
    font-size: 8vw;
  }
  .s {
    font-size: 5vw;
  }
  .d {
    font-size: 3vw;
    margin-right: 10%;
  } */
  .mg{
    width: 50%;
    height: auto;
    margin-left: 10%;
  }
  .c {
    position: fixed;
    margin-right: 0px;
    font-size: 3vw;
  }
  .lg {
    width: auto;
    height: 50px;
  }
  .btn {
    font-size: 15px;
    font-weight: 300px;
    top: 65%;
    margin-left: 30%;
  }
  .img {
    width: 100%;
  }
  .fr {
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .fr:hover{
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .en {
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .ar {
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .hh {
    margin-top: 30px;
    width: 60%;
    height: 60%;
  }
  .navul {
    margin-top: 20px;
    max-height: 160px;
  }
  .filter {
    width: 100%;
  }
  .header {
    .navbar {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
}
@media screen and (max-width: 1243px) {
  .mg{
    width: 75%;
    height: auto;
    margin-left: 5%;
  }
  .c {
    position: fixed;
    margin-right: 0px;
    font-size: 3vw;
  }
  .lg {
    width: auto;
    height: 50px;
  }
  .btn {
    font-size: 15px;
    font-weight: 300px;
    top: 65%;
  }
  .img {
    width: 100%;
  }
  .fr {
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .fr:hover{
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .en {
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .ar {
    width: 120%;
    height: auto;
    margin-right: 10px;
    margin-top: 20px;
  }
  .hh {
    margin-top: 30px;
    width: 60%;
    height: 60%;
  }
  .navul {
    margin-top: 20px;
    max-height: 160px;
  }
  .filter {
    width: 100%;
  }
  .header {
    .navbar {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
}
</style>
