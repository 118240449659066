export const API_PORT = '5000'
export const API_URL = `https://mazarsapi.instant-apps.io/`
export const DOMAIN_TITLE = 'Mazars 360°'

// Gouvernorats
export const GOUVS = [
    { name: 'Tunisie' },
    { name: 'Afghanistan' },
    { name: 'Afrique du Sud' },
    { name: 'Albanie' },
    { name: 'Algérie' },
    { name: 'Allemagne' },
    { name: 'Andorre' },
    { name: 'Angola' },
    { name: 'Anguilla' },
    { name: 'Arabie Saoudite' },
    { name: 'Argentine' },
    { name: 'Arménie' },
    { name: 'Australie' },
    { name: 'Autriche' },
    { name: 'Azerbaidjan' },
    { name: 'Bahamas' },
    { name: 'Bangladesh' },
    { name: 'Barbade' },
    { name: 'Bahrein' },
    { name: 'Belgique' },
    { name: 'Bélize' },
    { name: 'Bénin' },
    { name: 'Biélorussie' },
    { name: 'Bolivie' },
    { name: 'Botswana' },
    { name: 'Bhoutan' },
    { name: 'Boznie-Herzégovine' },
    { name: 'Brésil' },
    { name: 'Brunei' },
    { name: 'Bulgarie' },
    { name: 'Burkina Faso' },
    { name: 'Burundi' },
    { name: 'Cambodge' },
    { name: 'Cameroun' },
    { name: 'Canada' },
    { name: 'Cap-Vert' },
    { name: 'Chili' },
    { name: 'Chine' },
    { name: 'Chypre' },
    { name: 'Colombie' },
    { name: 'Comores' },
    { name: 'République du Congo' },
    { name: 'République Démocratique du Congo' },
    { name: 'Cook' },
    { name: 'Corée du Nord' },
    { name: 'Corée du Sud' },
    { name: 'Costa Rica' },
    { name: 'Côte d’Ivoire' },
    { name: 'Croatie' },
    { name: 'Cuba' },
    { name: 'Danemark' },
    { name: 'Djibouti' },
    { name: 'Dominique' },
    { name: 'Egypte' },
    { name: 'Emirats Arabes Unis' },
    { name: 'Equateur' },
    { name: 'Erythrée' },
    { name: 'Espagne' },
    { name: 'Estonie' },
    { name: 'Etats-Unis d’Amérique' },
    { name: 'Ethiopie' },
    { name: 'Fidji' },
    { name: 'Finlande' },
    { name: 'France' },
    { name: 'Gabon' },
    { name: 'Gambie' },
    { name: 'Géorgie' },
    { name: 'Ghana' },
    { name: 'Grèce' },
    { name: 'Grenade' },
    { name: 'Guatémala' },
    { name: 'Guinée' },
    { name: 'Guinée Bissau' },
    { name: 'Guinée Equatoriale' },
    { name: 'Guyana' },
    { name: 'Haïti' },
    { name: 'Honduras' },
    { name: 'Hongrie' },
    { name: 'Inde' },
    { name: 'Indonésie' },
    { name: 'Iran' },
    { name: 'Iraq' },
    { name: 'Irlande' },
    { name: 'Islande' },
    { name: 'Israël' },
    { name: 'italie' },
    { name: 'Jamaïque' },
    { name: 'Japon' },
    { name: 'Jordanie' },
    { name: 'Kazakhstan' },
    { name: 'Kenya' },
    { name: 'Kirghizistan' },
    { name: 'Kiribati' },
    { name: 'Koweït' },
    { name: 'Laos' },
    { name: 'Lesotho' },
    { name: 'Lettonie' },
    { name: 'Liban' },
    { name: 'Liberia' },
    { name: 'Liechtenstein' },
    { name: 'Lituanie' },
    { name: 'Luxembourg' },
    { name: 'Lybie' },
    { name: 'Macédoine' },
    { name: 'Madagascar' },
    { name: 'Malaisie' },
    { name: 'Malawi' },
    { name: 'Maldives' },
    { name: 'Mali' },
    { name: 'Malte' },
    { name: 'Maroc' },
    { name: 'Marshall' },
    { name: 'Maurice' },
    { name: 'Mauritanie' },
    { name: 'Mexique' },
    { name: 'Micronésie' },
    { name: 'Moldavie' },
    { name: 'Monaco' },
    { name: 'Mongolie' },
    { name: 'Mozambique' },
    { name: 'Namibie' },
    { name: 'Nauru' },
    { name: 'Nepal' },
    { name: 'Nicaragua' },
    { name: 'Niger' },
    { name: 'Nigéria' },
    { name: 'Nioué' },
    { name: 'Norvège' },
    { name: 'Nouvelle Zélande' },
    { name: 'Oman' },
    { name: 'Ouganda' },
    { name: 'Ouzbékistan' },
    { name: 'Pakistan' },
    { name: 'Palau' },
    { name: 'Palestine' },
    { name: 'Panama' },
    { name: 'Papouasie Nouvelle Guinée' },
    { name: 'Paraguay' },
    { name: 'Pays-Bas' },
    { name: 'Pérou' },
    { name: 'Philippines' },
    { name: 'Pologne' },
    { name: 'Portugal' },
    { name: 'Qatar' },
    { name: 'République centrafricaine' },
    { name: 'République Dominicaine' },
    { name: 'République Tchèque' },
    { name: 'Roumanie' },
    { name: 'Royaume Uni' },
    { name: 'Russie' },
    { name: 'Rwanda' },
    { name: 'Saint-Christophe-et-Niévès' },
    { name: 'Sainte-Lucie' },
    { name: 'Saint-Marin' },
    { name: 'Saint-Vincent-et-les Grenadines' },
    { name: 'Iles Salomon' },
    { name: 'Salvador' },
    { name: 'Samoa Occidentales' },
    { name: 'Sao Tomé et Principe' },
    { name: 'Sénégal' },
    { name: 'Serbie' },
    { name: 'Seychelles' },
    { name: 'Sierra Léone' },
    { name: 'Singapour' },
    { name: 'Slovaquie' },
    { name: 'Slovénie' },
    { name: 'Somalie' },
    { name: 'Soudan' },
    { name: 'Sri Lanka' },
    { name: 'Suède' },
    { name: 'Suisse' },
    { name: 'Suriname' },
    { name: 'Swaziland' },
    { name: 'Syrie' },
    { name: 'Tadjikistan' },
    { name: 'Taiwan' },
    { name: 'Tanzanie' },
    { name: 'Tchad' },
    { name: 'Thailande' },
    { name: 'Timor Oriental' },
    { name: 'Togo' },
    { name: 'Tonga' },
    { name: 'Trinité et Tobago' },
    { name: 'Turkménistan' },
    { name: 'Turquie' },
    { name: 'Tuvalu' },
    { name: 'Ukraine' },
    { name: 'Uruguay' },
    { name: 'Vanuatu' },
    { name: 'Vatican' },
    { name: 'Vénézuela' },
    { name: 'Vietnam' },
    { name: 'Yemen' },
    { name: 'Zambie' },
    { name: 'Zimbabwe' },
]

// modalContent
export const MODAL_CONENT_1 = {
    modalH4: 'Mazars',
    modalContent: `Vous ne rejoignez pas Mazars par hasard, vous choisissez Mazars : <br />
    une école mondiale d'excellence où vous serez mis au défi de vous développer et de grandir.
    Parce que nos collaborateurs déterminent notre succès, tout le monde ne fait pas l'affaire.
    La progression est liée à l'éducation, ce qui vous permet d'adapter votre carrière à vos aspirations, 
    tant à l'intérieur qu'à l'extérieur de notre cabinet. Avec une croissance ininterrompue, 
    nos collaborateurs jouent un rôle central dans ce que Mazars et nos clients vont faire. 
    Nous attendons votre contribution et récompensons votre ingéniosité. 
    Venez écrire le reste de notre histoire avec nous <br />
    - vous vous ferez aussi des amis en chemin. Mazars, le choix intelligent.`,
    slides: [
        {
            id: 'MvR30qxn-MM',
            backgroundImage: '/Mazars/monuments/Mazars_14.jpg',
            title: `${1}/3`,
            animatation: 'animate__fadeIn'
        }
    ]
}
export const MODAL_CONENT_2 = {
    modalH4: 'Bienvenue au cabinet Mazars en Tunisie',
    modalContent: ` 
    Nos lignes de métiers sont: <br /> 
    - Expertise et assistance comptable <br />
    - Actuariat <br />
    - Audit Financier <br />
    - Consulting <br />
    - Fiscalité <br />
    - Audit et Consulting IT <br />
    - Financial Advisory <br />
    Visitez notre site carrière pour découvrir nos métiers en détails: <a href="https://www.mazarscareers.com/tn">www.mazarscareers.com/tn</a>`,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_12.jpg',
        }
    ]

}
export const MODAL_CONENT_3 = {
    modalH4: 'Département Outsourcing',
    modalContent: `Comptables et gestionaires de paie . 
    Les équipes du pôle expertise et assistance comptable ont pour missions : <br />
    - Mission de tenue comptable <br />
    - Mission d’externalisation de la paie <br />
    - Autres missions de conseil auprès de nos clients (conseil juridique, fiscal et social par exemple) <br />
    - Les équipes interviennent également sur plusieurs secteurs d’activité (service, industrie, assurance, banque, immobilier et secteur public) 
     et types de clients variés (filiales françaises de groupes étrangers, filiales de grands groupes Français, PME/PMI/ETI, associations). 
     Cette non-sectorisation spécifique à Mazars favorise la diversification des expériences et le développement des compétences. <br />
    - L’expertise comptable implique une grande capacité d’adaptation, à l’environnement et aux enjeux clients. 
    C’est aussi un métier évolutif, qui permet d’accéder à des responsabilités opérationnelles et d’encadrement au bout de 4 ans d’expérience.
    Nous comptons 48% de femmes dans le département Outsourcing. 
    `,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_15.jpg',
        }
    ]
}
export const MODAL_CONENT_4 = {
    modalH4: '',
    modalContent: `Mazars en Tunisie s’engage à renforcer sa démarche RSE en s ’impliquant dans diverses actions pour atteindre les objectifs
    suivants d’ici 2024: <br />
    - Zero Plastic <br />
    - Zero Food Waste <br />
    - Paperless`,
    slides: [{
        backgroundImage: '/Mazars/monuments/Mazars_16.jpg',
    }
    ]
}
export const MODAL_CONENT_5 = {
    modalH4: "Il ne s'agit jamais seulement du travail",
    modalContent: `Chez Mazars, vous vous faites des amis pour la vie.
    Pour nous, le travail est fait pour être amusant.
    En nous rejoignant, vous vivrez une véritable aventure humaine et internationale grâce à notre style de management accessible et notre culture permanente du feedback & coaching. <br />
    Chez nous, vous aurez la possibilité de suggérer, de créer, de tester et d'échouer.
    Nous voulons que vous soyez votre vrai moi, quels que soient votre origine, votre culture et votre génération.
    Vous verrez, nous resterons en contact même si vous quittez l'entreprise.
    `,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Mazars/monuments/Mazars_2.jpeg',
            backgroundImage: '/Mazars/monuments/Mazars_2.jpeg',
            title: `${1}/4`,
            animatation: 'animate__fadeIn'
        }, {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Mazars/monuments/Mazars_3.jpeg',
            backgroundImage: '/Mazars/monuments/Mazars_3.jpeg',
            title: `${2}/4`,
            animatation: 'animate__fadeIn'
        }, {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Mazars/monuments/Mazars_4.jpeg',
            backgroundImage: '/Mazars/monuments/Mazars_4.jpeg',
            title: `${3}/4`,
            animatation: 'animate__fadeIn'
        }, {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Mazars/monuments/Mazars_5.jpeg',
            backgroundImage: '/Mazars/monuments/Mazars_5.jpeg',
            title: `${4}/4`,
            animatation: 'animate__fadeIn'
        }
    ]
}
export const MODAL_CONENT_6 = {
    modalH4: "",
    modalContent: `Vous n'aurez jamais une seconde chance de démarrer votre carrière.<br />
     Mais vous pouvez vous remettre en question en permanence pour évoluer.
    Pourquoi arrêter d'apprendre lorsque vous laissez derrière vous la salle de classe traditionnelle ? 
    Mazars est votre école de commerce professionnelle. Notre propre Mazars University vous aidera à développer vos 
    compétences techniques et de leadership, tout en veillant à ce que vous soyez parfaitement équipé pour demain 
    et pour chaque étape de votre parcours professionnel. Avec plus de 90 pays, vous bénéficierez très tôt d'une exposition internationale.<br />
    Nous comptons en moyenne 62 heures de formation technique et soft skills par employé annuellement.
    `,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_17.jpg',
        }
    ]
}
export const MODAL_CONENT_7 = {
    modalH4: 'Département Audit, IT et Tax',
    modalContent: `Ici se trouvent les départements Audit, IT et Tax:<br />
    Nos collaborateurs IT travaillent de façon transversale sur l’audit et le consulting. En effet, chez Mazars, il est possible de changer de métier sans quitter l’entreprise: <br />
    • Ces passerelles internes se font généralement à partir de la 4ème année d’expérience en Audit financier vers les lignes métiers Conseil. Également de l’Expertise comptable vers l’audit ou la fiscalité !<br />
    • La mobilité métier peut également se faire de manière plus ponctuelle lors de missions spécifiques.<br />
    L’Audit est divisé sur 3 secteurs: Industries & Services, Banque et Assurances.
    Notre équipe de spécialistes TAX assure une veille fiscale permanente, permettant de développer un haut niveau de service en matière de fiscalité notamment :<br />
    • Intervenir au service d’entreprises en difficulté, afin de les accompagner dans la mise en œuvre de solutions de redressement :<br />
    • Assurer un support aux équipes d’audit et d’outsourcing.
    `,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_20.jpg',
        }
    ]
}
export const MODAL_CONENT_8 = {
    modalH4: 'Le département Audit',
    modalContent: `55% de l’équipe Audit sont des femmes. <br />
    Le saviez-vous? <br />
    Avec 92 pays, vous bénéficiez très tôt 
    d'une exposition internationale.
    Nous proposons une série de programmes 
    différents qui peuvent se dérouler à différents 
    stades de votre carrière : <br />
    • MOVE <br />
    • Détachements <br />
    • Transferts <br />
    5 collaborateurs ont bénéficié
    d’une mobilité internationale
    en 2020<br />
    Nos offrons la possibilité à nos collaborateurs 
    à partir du grade senior et + de bénéficier de 
    ces programmes, les derniers Move de nos 
    mazariens: Hong Kong, Luxembourg, Qatar
    `,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_10.jpg',
        }
    ]
}
export const MODAL_CONENT_9 = {
    modalH4: 'Open space',
    modalContent: `Open space destiné aux Managers et Senior Managers <br />
    Aujourd’hui nous comptons 52% de femmes Seniors et 42% de femmes Managers. 
    `,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_11.jpg',
        }
    ]
}
export const MODAL_CONENT_10 = {
    modalH4: 'Mazars',
    modalContent: `Mazars en Tunisie compte 9 associés divisés sur différents métiers et secteurs. <br />
    Nos associés sont jeunes et accessibles. <br />
    Nous avons une hiérarchie relativement plate 
    caractérisée par une politique de la porte 
    ouverte à tous les niveaux. <br />
    Nous encourageons et avons mis en place 
    des processus de mentorat.
    `,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_18.jpg',
        }
    ]
}
export const MODAL_CONENT_11 = {
    modalH4: 'Département Conseil Financier et RH',
    modalContent: `Le conseil réunit des profils très divers pour répondre à des missions variées, financiers, psychologue du travail,
    experts comptables et techniciens travaillent ensemble pour répondre aux différents projets... <br />
    Nous comptons 73% de femmes consultantes au sein de Mazars en Tunisie`,
    slides: [
        {
            backgroundImage: '/Mazars/monuments/Mazars_19.jpg',
        }
    ]
}

export const CHROMA_VIDEOS = {
    5: {
        index: 1,
        title: 'Bureau 1',
        fr: '/videos/interactive/AHMED FR.webm',
        en: '/videos/interactive/AHMED ENG.webm',
        ar: '/videos/interactive/AHMED AR.webm'
    },
    7: {
        index: 2,
        title: 'Bureau 1',
        fr: '/videos/interactive/EMNA NEW FR.webm',
        en: '/videos/interactive/EMNA NEW ENG.webm',
        ar: '/videos/interactive/EMNA NEW AR.webm'
    },
    9: {
        index: 3,
        title: 'Bureau 1',
        fr: '/videos/interactive/NESSRINE FR.webm',
        en: '/videos/interactive/NESSRINE ENG.webm',
        ar: '/videos/interactive/NESSRINE AR.webm'
    },
    10: {
        index: 4,
        title: 'Bureau 1',
        fr: '/videos/interactive/LENDA FR.webm',
        en: '/videos/interactive/LENDA ENG.webm',
        ar: '/videos/interactive/LENDA AR.webm'
    },
    13: {
        index: 5,
        title: 'Bureau 1',
        fr: '/videos/interactive/RAHMA FR.webm',
        en: '/videos/interactive/RAHMA ENG.webm',
        ar: '/videos/interactive/RAHMA AR.webm'
    },
    12: {
        index: 6,
        title: 'Bureau 1',
        fr: '/videos/interactive/KARIM FR.webm',
        en: '/videos/interactive/KARIM ENG.webm',
        ar: '/videos/interactive/KARIM AR.webm'
    }
}

export const QUIZOBJECTS = [{
    index: 0,
    src: '/vote360/quiz/q1.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q1.webm',
    clickedObj: [{
        name: 'toScene2',
        popoverContent: 'الإجابة الأولى',
        texture: '/vote360/marker.png',
        pos: { x: -20.5, y: -1.48, z: -5 }
    },

    {
        name: 'toScene1',
        popoverContent: 'الإجابة الثانية',
        texture: '/vote360/marker.png',
        pos: { x: -14.5, y: -1.25, z: 5 }
    },
    ],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 1,
    src: '/vote360/quiz/q1r2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q1r2.webm',
    clickedObj: [{
        name: 'toScene5',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 2,
    src: '/vote360/quiz/q2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q2.webm',
    clickedObj: [{
        name: 'toScene3',
        popoverContent: 'الإجابة الأولى',
        texture: '/vote360/marker.png',
        pos: { x: -20.5, y: -1.48, z: -5 }
    },
    {
        name: 'toScene4',
        popoverContent: 'الإجابة الثانية',
        texture: '/vote360/marker.png',
        pos: { x: -14.5, y: -1.25, z: 5 }
    }
    ],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 3,
    src: '/vote360/quiz/q2r1.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q2r1.webm',
    clickedObj: [{
        name: 'toScene5',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 4,
    src: '/vote360/quiz/q2r2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q2r2.webm',
    clickedObj: [{
        name: 'toScene5',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 5,
    src: '/vote360/quiz/q3.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q3.webm',
    clickedObj: [{
        name: 'toScene6',
        popoverContent: 'الإجابة الأولى',
        texture: '/vote360/marker.png',
        pos: { x: -20.5, y: -1.48, z: -5 }
    },
    {
        name: 'toScene7',
        popoverContent: 'الإجابة الثانية',
        texture: '/vote360/marker.png',
        pos: { x: -14.5, y: -1.25, z: 5 }
    }
    ],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 6,
    src: '/vote360/quiz/q3r1.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q3r1.webm',
    clickedObj: [{
        name: 'toScene8',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 7,
    src: '/vote360/quiz/q3r2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q3r2.webm',
    clickedObj: [{
        name: 'toScene8',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 8,
    src: '/vote360/quiz/q4.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q4.webm',
    clickedObj: [{
        name: 'toScene9',
        popoverContent: 'الإجابة الأولى',
        texture: '/vote360/marker.png',
        pos: { x: -20.5, y: -1.48, z: -5 }
    },
    {
        name: 'toScene10',
        popoverContent: 'الإجابة الثانية',
        texture: '/vote360/marker.png',
        pos: { x: -14.5, y: -1.25, z: 5 }
    }
    ],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 9,
    src: '/vote360/quiz/q4r1.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q4r1.webm',
    clickedObj: [{
        name: 'toScene11',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 10,
    src: '/vote360/quiz/q4r2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q4r2.webm',
    clickedObj: [{
        name: 'toScene11',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 11,
    src: '/vote360/quiz/q5.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q5.webm',
    clickedObj: [{
        name: 'toScene12',
        popoverContent: 'الإجابة الأولى',
        texture: '/vote360/marker.png',
        pos: { x: -20.5, y: -1.48, z: -5 }
    },
    {
        name: 'toScene13',
        popoverContent: 'الإجابة الثانية',
        texture: '/vote360/marker.png',
        pos: { x: -14.5, y: -1.25, z: 5 }
    }
    ],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 12,
    src: '/vote360/quiz/q5r1.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q5r1.webm',
    clickedObj: [{
        name: 'toScene14',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 13,
    src: '/vote360/quiz/q5r2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q5r2.webm',
    clickedObj: [{
        name: 'toScene14',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 14,
    src: '/vote360/quiz/q6.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q6.webm',
    clickedObj: [{
        name: 'toScene15',
        popoverContent: 'الإجابة الأولى',
        texture: '/vote360/marker.png',
        pos: { x: -20.5, y: -1.48, z: -5 }
    },
    {
        name: 'toScene16',
        popoverContent: 'الإجابة الثانية',
        texture: '/vote360/marker.png',
        pos: { x: -14.5, y: -1.25, z: 5 }
    }
    ],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 15,
    src: '/vote360/quiz/q6r1.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q6r1.webm',
    clickedObj: [{
        name: 'toScene17',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 16,
    src: '/vote360/quiz/q6r2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q6r2.webm',
    clickedObj: [{
        name: 'toScene17',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 17,
    src: '/vote360/quiz/q7.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q7.webm',
    clickedObj: [{
        name: 'toScene18',
        popoverContent: 'الإجابة الأولى',
        texture: '/vote360/marker.png',
        pos: { x: -20.5, y: -1.48, z: -5 }
    },
    {
        name: 'toScene19',
        popoverContent: 'الإجابة الثانية',
        texture: '/vote360/marker.png',
        pos: { x: -14.5, y: -1.25, z: 5 }
    }
    ],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 18,
    src: '/vote360/quiz/q7r1.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q7r1.webm',
    clickedObj: [{
        name: 'goToHome',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
},
{
    index: 19,
    src: '/vote360/quiz/q7r2.mp4',
    signeVideoSrc: '/vote360/quiz/signlanguage/q7r2.webm',
    clickedObj: [{
        name: 'goToHome',
        popoverContent: 'واصل',
        texture: '/vote360/markerQ.png',
        pos: { x: -100, y: -23.5, z: 5 }
    }],
    cameraSetPos: { x: 4, y: 0, z: -1 }
}
]
export const Camera_Positions = [{
    index: 0,
    position: { x: -80, y: 1, z: -40 }
},
{
    index: 1,
    position: { x: -50, y: 10, z: 8 }
},
{
    index: 2,
    position: { x: 0, y: 5, z: 23 }
},
{
    index: 3,
    position: { x: -10, y: 0, z: 20 }
},
{
    index: 4,
    position: { x: 150, y: 5, z: 0 }
},
{
    index: 5,
    position: { x: -160, y: 8, z: 80 }
},
{
    index: 6,
    position: { x: -8, y: 0, z: -15 }
},
{
    index: 7,
    position: { x: -50, y: 14, z: -100 }
},
{
    index: 8,
    position: { x: 300, y: 0, z: -100 }
},
{
    index: 9,
    position: { x: -50, y: 0, z: 55 }
},
{
    index: 10,
    position: { x: -40, y: 17, z: 35 }
},
{
    index: 11,
    position: { x: 0, y: 30, z: -100 }
},
{
    index: 12,
    position: { x: -20, y: 12, z: 200 }
},
{
    index: 13,
    position: { x: -100, y: 50, z: -100 }
},
{
    index: 14,
    position: { x: 500, y: 10, z: 500 }
}
]
export const Scene_Names = [{
    index: 0,
    Scene_Name: 'rdcWelcome'
},
{
    index: 1,
    Scene_Name: 'rdc Hall'},
{
    index: 2,
    Scene_Name: 'rdc openSpace'},
{
    index: 3,
    Scene_Name: 'rdc openSpaceHall'},
{
    index: 4,
    Scene_Name: 'rdc OutSide'},
{
    index: 5,
    Scene_Name: 'rdc meetingRoom'},
{
    index: 6,
    Scene_Name: 'first Welcome'},
{
    index: 7,
    Scene_Name: 'first office1'},
{
    index: 8,
    Scene_Name: 'first openSpace1'},
{
    index: 9,
    Scene_Name: 'first openSpace2'},
{
    index: 10,
    Scene_Name: 'first office2'},
{
    index: 11,
    Scene_Name: 'second welcome'},
{
    index: 12,
    Scene_Name: 'second openSpace'},
{
    index: 13,
    Scene_Name: 'second meetingRoom'},
{
    index: 14,
    Scene_Name: 'third outside'}
]
export const TOOLTIP = [
    {
        index: 0,
        title: 'video',
        src: '/videos/button/PLAY2.webm'
    },
    {
        index: 1,
        title: 'info',
        src: '/videos/button/INFO2.webm'
    },
    {
        index: 2,
        title: 'quizz',
        src: '/videos/button/QUIZ2.webm'
    },
    {
        index: 3,
        title: 'stop',
        src: '/videos/button/STOP.webm'
    }

]