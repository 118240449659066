<template>
  <div>
    <loader v-if="!mySwiper"></loader>
    <!-- Slider main container -->
    <div
      ref="swiperContainer"
      class="swiper-container"
      :style="{ height: height }"
      @mouseover="
        mySwiper
          ? (() => {
              mySwiper.autoplay.stop()
              progressWidth = 0
              progressWidthDelay = 500
            })()
          : null
      "
      @mouseleave="
        mySwiper
          ? (() => {
              mySwiper.autoplay.start()
              progressWidth = 100
              progressWidthDelay = mySwiper.params.autoplay.delay
            })()
          : null
      "
    >
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <slide
          v-for="(slide, index) in slides"
          :key="index"
          :slide="{ ...slide, index }"
          :isActive="activeIndex === index"
          :justImage="justImage"
          :content="content[0]"
          :scrollData="scrollData"
          :autoplay="true"
          @imageLoaded="onImageLoaded"
        >
        </slide>
      </div>
      <!-- If we need pagination -->
      <div v-if="slides.length > 1" style="visibility: hidden;" class="swiper-pagination"></div>
      <div v-if="slides.length > 1" class="swiper-progressBar">
        <div
          class="swiper-bar"
          :style="{
            transition: 'width ' + progressWidthDelay + 'ms linear',
            width: progressWidth + '%'
          }"
        ></div>
      </div>
      <!-- If we need navigation buttons -->
      <nav class="nav-slit" v-if="mySwiper !== null && slides.length > 1">
        <a
          class="prev"
          href="javascript:void(0)"
          @click="activeIndex === 0 ? mySwiper.slideTo(slides.length - 1) : mySwiper.slidePrev()"
        >
          <span class="icon-wrap">
            <i class="icon fa fa-angle-left"></i>
          </span>
          <div>
            <h3 id="title-prev">
              {{ slides[activeIndex === 0 ? slides.length - 1 : activeIndex - 1].title }}
            </h3>
            <img
              id="thumb-prev"
              :src="slides[activeIndex === 0 ? slides.length - 1 : activeIndex - 1].thumbUrl"
              alt="Previous thumb"
            />
          </div>
        </a>
        <a
          class="next"
          href="javascript:void(0)"
          @click="activeIndex === slides.length - 1 ? mySwiper.slideTo(0) : mySwiper.slideNext()"
        >
          <span class="icon-wrap">
            <i class="icon fa fa-angle-right"></i>
          </span>
          <div>
            <h3 id="title-next">
              {{ slides[activeIndex === slides.length - 1 ? 0 : activeIndex + 1].title }}
            </h3>
            <img
              id="thumb-next"
              :src="slides[activeIndex === slides.length - 1 ? 0 : activeIndex + 1].thumbUrl"
              alt="Next thumb"
            />
          </div>
        </a>
      </nav>
    </div>
  </div>
</template>
<script>
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import Swiper from 'swiper'

import Loader from './Loader.vue'
import Slide from './Slide.vue'

export default {
  name: 'awesomeSlider',
  components: {
    Loader,
    Slide
  },
  props: {
    slides: {
      type: Array,
      required: true
    },
    height: {
      type: String,
      required: false,
      default: '75vh'
    },
    parallax: {
      type: Boolean,
      required: false,
      default: true
    },
    justImage: {
      type: Boolean,
      required: false,
      default: false
    },
    content: {
      type: Array,
      required: false,
      default: () => [{
        image: 'null.png',
        textH1: 'متحف<br />الديمقراطية'
      }]
    }
  },
  setup(props, { root }) {
    const progressWidthDelay = ref(2000)
    const progressWidth = ref(0)
    const swiperContainer = ref(null)
    const activeIndex = ref(0)
    const mySwiper = ref(null)
    const scrollData = ref({
      top: 0,
      opacity: 1,
      backgroundPosition: 0
    })
    const initSlider = (autoplayDelay = 500) => {
      mySwiper.value = new Swiper(swiperContainer.value, {
        pagination: props.slides.length < 1 ? false : {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
          renderBullet: function(index, className) {
            return `<span style="visibility: hidden;" class="${className}"><span class="tooltip-content"><span class="tooltip-text"><span class="tooltip-inner"><img src="${props.slides[index].thumbUrl}" class="img-responsive" style="width: 130px; height: 85px;"></span></span></span></span>`
          }
        },
        autoHeight: props.justImage,
        autoplay: props.slides.length < 1 ? false : {
          delay: autoplayDelay,
          disableOnInteraction: false
        },
        spaceBetween: 0,
        on: {
          slideChange: () => {
            activeIndex.value = mySwiper.value ? mySwiper.value.activeIndex : 0
          },
          transitionEnd: () => {
            progressWidth.value = 100
            progressWidthDelay.value = mySwiper.value.params.autoplay.delay
          },
          transitionStart: () => {
            progressWidth.value = 0
            progressWidthDelay.value = 0
          }
        },
        grabCursor: props.slides.length > 1,
        init: false
      })
      mySwiper.value.init()
    }
    const getPosition = e => {
      const isNotFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') === -1
      let x = 0
      let y = 0
      if (e) {
        x += e.offsetLeft - e.scrollLeft + (isNotFirefox ? e.clientLeft : 0)
        y += e.offsetTop - e.scrollTop + (isNotFirefox ? e.clientTop : 0)
        e = e.offsetParent
      }
      return { left: x + scrollX, top: y + scrollY }
    }
    const handleScroll = e => {
      // Any code to be executed when the window is scrolled
      const targetHeight = swiperContainer.value.clientHeight
      const position = getPosition(swiperContainer.value)
      const scrollPercent = targetHeight - position.top
      if (scrollY < targetHeight + swiperContainer.value.offsetTop) {
        scrollData.value = {
          top: 1 - (scrollPercent - targetHeight + swiperContainer.value.offsetTop) / 2 - 1,
          opacity: (scrollPercent + swiperContainer.value.offsetTop) / targetHeight,
          backgroundPosition: (swiperContainer.value.offsetTop - position.top) * 0.2 - 0
        }
      }
    }
    onMounted(() => {
      setTimeout(() => {
        initSlider(progressWidthDelay.value)
        mySwiper.value.autoplay.start()
        progressWidth.value = 100
        if (outerWidth > 850 && props.parallax) {
          window.addEventListener('scroll', handleScroll)
        }
      })
    })
    onUnmounted(() => {
      mySwiper.value = null
      if (outerWidth > 795 && props.parallax) {
        window.removeEventListener('scroll', handleScroll)
      }
    })
    const onImageLoaded = result => {
      // console.log(result);
    }
    return {
      mySwiper,
      swiperContainer,
      activeIndex,
      progressWidthDelay,
      scrollData,
      progressWidth,
      onImageLoaded
    }
  }
}
</script>
<style scoped lang="scss">
.swiper-container {
  width: 100%;
  min-height: 150px;
  margin-top: 0px;
}
</style>
